import 'isomorphic-fetch'
import React, { useEffect } from 'react'
import i18n from '../i18n/i18n'

const WebiaForm = ({ lang, webiaId }) => {
  useEffect(() => {
    const script = document.createElement('script')

    script.innerHTML = `(function(w,d,t,u,f,k,a){w[f]=w[f]||function(){(w[f].a=w[f].a||[]).push(arguments)};k=d.createElement(t),a=d.getElementsByTagName(t)[0],k.async=1,k.src=u,a.parentNode.insertBefore(k,a)})(window, document, "script", "https://kalenteri.webia.fi/static/minjs/tag.js", "bc");bc(${webiaId}, {target: 'bkngcldr${webiaId}',});`
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
      delete window.bc
    }
  }, [webiaId])

  return (
    <div>
      <h1 id="reserveCabin" className="reservation__header">
        {i18n[lang].reservationForm.reservation}
      </h1>
      <div id={`bkngcldr${webiaId}`}></div>
    </div>
  )
}

export default WebiaForm
