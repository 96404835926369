import {
  Alert, Button, Checkbox, DatePicker, Form,
  Input, notification, Result, Select
} from 'antd'
import 'antd/dist/antd.css'
import enLocale from 'antd/es/date-picker/locale/en_US'
import fiLocale from 'antd/es/date-picker/locale/fi_FI'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import 'isomorphic-fetch'
import moment from 'moment'
import React, { useState } from 'react'
import {
  BrowserView, isBrowser, isMobile, MobileView
} from 'react-device-detect'
import 'rmc-date-picker/assets/index.css'
import MobileDatePicker from 'rmc-date-picker/lib/DatePicker'
import Popup from 'rmc-date-picker/lib/Popup'
import 'rmc-picker/assets/index.css'
import 'rmc-picker/assets/popup.css'
import i18n from './../i18n/i18n'
import './styles/cabin-form.css'








const baseUrl = 'https://eansn82n35.execute-api.eu-north-1.amazonaws.com/prod'
const priceUrl = (
  cabin,
  startDate,
  endDate,
  numberOfPersons,
  hasBreakfast,
  hasSauna,
  lang
) =>
  `${baseUrl}/cabin/${cabin}/price?startDate=${startDate}&endDate=${endDate}&numberOfPersons=${numberOfPersons}&lang=${lang}&sauna=${
    hasSauna ? 'true' : 'false'
  }&breakfast=${hasBreakfast ? 'true' : 'false'}`

const formatDate = date =>
  `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`

const MobileDate = ({ onChange, value, defaultDate, monthNames, msgs }) => {
  const getMonth = month => monthNames[month]
  const datePicker = (
    <MobileDatePicker
      mode="date"
      formatMonth={getMonth}
      defaultDate={defaultDate}
    />
  )

  const triggerChange = changedValue => {
    if (onChange) {
      onChange(changedValue)
    }
  }

  const onButtonClick = e => {
    e.preventDefault()
  }

  return (
    <Popup
      datePicker={datePicker}
      transitionName="rmc-picker-popup-slide-fade"
      maskTransitionName="rmc-picker-popup-fade"
      title={msgs.callToAction}
      okText={msgs.select}
      dismissText={msgs.dismiss}
      onChange={triggerChange}
    >
      <Button onClick={onButtonClick}>
        {(value && formatDate(value)) || msgs.callToAction}
      </Button>
    </Popup>
  )
}

const CabinForm = props => {
  const msgs = i18n[props.lang].reservationForm
  const locale = props.lang === 'fi' ? fiLocale : enLocale

  const [isSubmitting, setSubmitting] = useState(false)
  const [isSubmitted, setSubmitted] = useState(false)

  const [currentDate] = useState(new Date())
  const [numberOfPersons, setNumberOfPersons] = useState(1)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [hasPet, setHasPet] = useState(false)
  const [hasSauna, setHasSauna] = useState(false)
  const [hasBreakfast, setHasBreakfast] = useState(false)

  const onPetChange = e => {
    setHasPet(e.target.checked)
  }

  const onSaunaChange = e => {
    setHasSauna(e.target.checked)

    onPricingDataChange(
      startDate,
      endDate,
      numberOfPersons,
      hasBreakfast,
      e.target.checked
    )
  }

  const onBreakfastChange = e => {
    setHasBreakfast(e.target.checked)

    onPricingDataChange(
      startDate,
      endDate,
      numberOfPersons,
      e.target.checked,
      hasSauna
    )
  }

  const onDateChange = ($, [startDate, endDate]) => {
    setStartDate(startDate)
    setEndDate(endDate)

    onPricingDataChange(
      startDate,
      endDate,
      numberOfPersons,
      hasBreakfast,
      hasSauna
    )
  }

  const onMobileStartDataChange = (newDate, b) => {
    setStartDate(newDate)
    onPricingDataChange(
      newDate,
      endDate,
      numberOfPersons,
      hasBreakfast,
      hasSauna
    )
  }

  const onMobileEndDataChange = newDate => {
    setEndDate(newDate)
    onPricingDataChange(
      startDate,
      newDate,
      numberOfPersons,
      hasBreakfast,
      hasSauna
    )
  }

  const onNumberOfPersonsChange = newNumberOfPersons => {
    setNumberOfPersons(newNumberOfPersons)
    onPricingDataChange(
      startDate,
      endDate,
      newNumberOfPersons,
      hasBreakfast,
      hasSauna
    )
  }

  const onPricingDataChange = (
    startDate,
    endDate,
    numberOfPersons,
    hasBreakfast,
    hasSauna
  ) => {
    if (!startDate || !endDate || !numberOfPersons) {
      return
    }

    fetch(
      priceUrl(
        props.cabinSlug,
        startDate,
        endDate,
        numberOfPersons,
        hasBreakfast,
        hasSauna,
        props.lang
      ),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then(response => response.json())
      .then(result => {
        if (result.type === 'error') {
          props.form.setFields({
            price: {
              value: '-',
              errors: [new Error(result.result)],
            },
          })

          trackCustomEvent({
            category: 'Cabin form',
            action: 'No price received',
            label: props.cabinSlug,
          })
        } else {
          props.form.setFields({
            price: {
              value: result.result,
            },
          })

          trackCustomEvent({
            category: 'Cabin form',
            action: 'Price received',
            label: props.cabinSlug,
            value: result.result,
          })
        }
      })
  }

  const { getFieldDecorator } = props.form

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  }

  const jsonToUrlEncoded = json => {
    let urlEncoded = []
    for (let property in json) {
      const encodedKey = encodeURIComponent(property)
      const encodedValue = encodeURIComponent(json[property])
      urlEncoded.push(encodedKey + '=' + encodedValue)
    }
    urlEncoded = urlEncoded.join('&')

    return urlEncoded
  }

  const handleSubmit = e => {
    e.preventDefault()

    trackCustomEvent({
      category: 'Cabin form',
      action: 'Start submit form',
      label: props.cabinSlug,
    })

    props.form.validateFields((err, values) => {
      if (!err) {
        setSubmitting(true)        

        values.cabinSlug = props.cabinSlug
        values['form-name'] = 'varauslomake'
        values.language = props.lang

        if (values.dates) {
          values.dateStart = values.dates[0].format('YYYY-MM-DD')
          values.dateEnd = values.dates[1].format('YYYY-MM-DD')
        } else {
          values.dateStart = moment(values.dateStart).format('YYYY-MM-DD')
          values.dateEnd = moment(values.dateEnd).format('YYYY-MM-DD')
        }

        delete values.dates

        const formBody = jsonToUrlEncoded(values)

        fetch(window.location.href, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
          body: formBody,
        })
          .then(() => {
            setSubmitting(false)
            setSubmitted(true)

            notification['success']({
              message: msgs.reservationSent.message,
              description: msgs.reservationSent.description,
            })

            trackCustomEvent({
              category: 'Cabin form',
              action: 'Submit successful',
              label: props.cabinSlug,
            })
          })
          .catch(error => {
            console.error('Error submitting form', error)

            window.Sentry.captureException(error)

            notification['error']({
              message: msgs.reservationFailed.message,
              description: msgs.reservationFailed.description,
            })

            trackCustomEvent({
              category: 'Cabin form',
              action: 'Submit error',
              label: props.cabinSlug,
            })
          })
      } else {
        console.error('Errors in form. Do not submit.')
      }
    })
  }

  return isSubmitted ? (
    <Result
      status="success"
      title={msgs.reservationSent.message}
      subTitle={msgs.reservationSent.description}
      extra={[
        <Button key="console" onClick={() => setSubmitted(false)}>
          {msgs.reservationSent.reserveAgain}
        </Button>,
      ]}
    />
  ) : (
    <div>
      <h1 id="reserveCabin" className="reservation__header">{msgs.reservation}</h1>

      {props.cabinCalendarId && (
        <iframe
          title="Calendar"
          src={`https://calendar.google.com/calendar/embed?showTitle=0&showPrint=0&showTabs=0&showCalendars=0&wkst=2&bgcolor=%23FCF7EF&src=${props.cabinCalendarId}%40group.calendar.google.com&ctz=Europe/Helsinki`}
          style={{ border: 0 }}
          width="100%"
          height="600px"
          frameBorder="0"
          scrolling="no"
        />
      )}

      <Alert
        message={msgs.general.message}
        description={
          <div>
            <div
              dangerouslySetInnerHTML={{ __html: msgs.general.description1 }}
            />
            <div className="calendar-example">
              <div className="calendar-example__calendar">
                <div className="calendar-example__day">
                  <span className="calendar-example__day-number">12</span>
                </div>
                <div className="calendar-example__day">
                  <span className="calendar-example__day-number">13</span>
                </div>
                <div className="calendar-example__day">
                  <span className="calendar-example__day-number">14</span>
                </div>
                <div className="calendar-example__day">
                  <span className="calendar-example__day-number">15</span>
                </div>
              </div>
              <div className="calendar-example__reservation calendar-example__reservation--possible calendar-example__reservation--possible-before">
                {msgs.general.possibleReservation}
              </div>
              <div className="calendar-example__reservation calendar-example__reservation--current">
                {msgs.general.existingReservation}
              </div>
              <div className="calendar-example__reservation calendar-example__reservation--possible calendar-example__reservation--possible-after">
                {msgs.general.possibleReservation}
              </div>
            </div>
            {props.reservationInstructions ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: props.reservationInstructions,
                }}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: msgs.general.description2 }}
              />
            )}
          </div>
        }
        type="info"
        showIcon
      />

      <Form
        {...formItemLayout}
        name="varauslomake"
        className="cabin__form"
        onSubmit={handleSubmit}
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="varauslomake" />

        <Form.Item label="Mökki">
          {getFieldDecorator('cabin', {
            initialValue: props.cabinName,
          })(<Input disabled name="cabin" />)}
        </Form.Item>
        <Form.Item label={msgs.numberOfPersons.title}>
          {getFieldDecorator('numberOfPersons', {
            rules: [
              { required: true, message: msgs.numberOfPersons.errors.required },
            ],
            initialValue: '1',
          })(
            <Select
              style={{ width: '50%' }}
              name="numberOfPersons"
              onChange={onNumberOfPersonsChange}
            >
              <Select.Option value="1">1</Select.Option>
              <Select.Option value="2">2</Select.Option>
              <Select.Option value="3">3</Select.Option>
              <Select.Option value="4">4</Select.Option>
              <Select.Option value="5">5</Select.Option>
              <Select.Option value="6">6</Select.Option>
              <Select.Option value="7">7</Select.Option>
              <Select.Option value="8">8</Select.Option>
              <Select.Option value="9">9</Select.Option>
              <Select.Option value="10">10</Select.Option>
              <Select.Option value="11">11</Select.Option>
              <Select.Option value="12">12</Select.Option>
            </Select>
          )}
        </Form.Item>
        <BrowserView>
          <input type="hidden" name="dateStart" value="dateStartPlaceholder" />
          <input type="hidden" name="dateEnd" value="dateEndPlaceholder" />
          <Form.Item label={msgs.dates.title}>
            {getFieldDecorator('dates', {
              rules: [
                {
                  required: isBrowser,
                  message: msgs.dates.errors.required,
                },
              ],
            })(
              <DatePicker.RangePicker
                onChange={onDateChange}
                name="date"
                placeholder={[msgs.startDate.title, msgs.endDate.title]}
                locale={locale}
              />
            )}
          </Form.Item>
        </BrowserView>

        <MobileView>
          <Form.Item label={msgs.startDate.title}>
            {getFieldDecorator('dateStart', {
              rules: [
                {
                  required: isMobile,
                  message: msgs.startDate.errors.required,
                },
              ],
            })(
              <MobileDate
                defaultDate={startDate || currentDate}
                monthNames={msgs.months}
                msgs={msgs.startDate}
                onChange={onMobileStartDataChange}
              />
            )}
          </Form.Item>

          <Form.Item label={msgs.endDate.title}>
            {getFieldDecorator('dateEnd', {
              rules: [
                {
                  required: isMobile,
                  message: msgs.endDate.errors.required,
                },
              ],
            })(
              <MobileDate
                defaultDate={endDate || startDate || currentDate}
                monthNames={msgs.months}
                msgs={msgs.endDate}
                onChange={onMobileEndDataChange}
              />
            )}
          </Form.Item>
        </MobileView>

        {props.hasBreakfast && (
          <Form.Item label={msgs.breakfast.title}>
            {getFieldDecorator('hasBreakfast')(
              <Checkbox name="hasBreakfast" onChange={onBreakfastChange}>
                {msgs.breakfast.description}
              </Checkbox>
            )}
          </Form.Item>
        )}

        {props.hasSauna && (
          <Form.Item label={msgs.sauna.title}>
            {getFieldDecorator('hasSauna')(
              <Checkbox name="hasSauna" onChange={onSaunaChange}>
                {msgs.sauna.description}
              </Checkbox>
            )}
          </Form.Item>
        )}

        <Form.Item label={msgs.price.title}>
          {getFieldDecorator('price')(
            <Input
              style={{ width: '50%' }}
              addonAfter="€"
              placeholder={msgs.price.placeholder}
              name="price"
              disabled
            />
          )}
        </Form.Item>
        <Form.Item label={msgs.name.title}>
          {getFieldDecorator('name', {
            rules: [{ required: true, message: msgs.name.errors.required }],
          })(<Input placeholder={msgs.name.placeholder} name="name" />)}
        </Form.Item>
        <Form.Item label={msgs.email.title}>
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: msgs.email.errors.required,
              },
              {
                required: true,
                message: msgs.email.errors.validEmail,
              },
            ],
          })(<Input placeholder={msgs.email.placeholder} name="email" />)}
        </Form.Item>
        <Form.Item label={msgs.phone.title}>
          {getFieldDecorator('phone', {
            rules: [{ required: true, message: msgs.phone.errors.required }],
          })(<Input placeholder={msgs.phone.placeholder} name="phone" />)}
        </Form.Item>
        <Form.Item label={msgs.pet.title}>
          {getFieldDecorator('pet')(
            <Checkbox name="pet" onChange={onPetChange}>
              {msgs.pet.description}
            </Checkbox>
          )}
          {hasPet && (
            <Alert
              message={msgs.pet.title}
              description={
                <div
                  dangerouslySetInnerHTML={{ __html: msgs.pet.instructions }}
                />
              }
              type="info"
              showIcon
            />
          )}
        </Form.Item>
        <Form.Item label={msgs.extra.title}>
          {getFieldDecorator('extraInfo')(
            <Input.TextArea
              rows={6}
              placeholder={msgs.extra.placeholder}
              name="extraInfo"
            />
          )}
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={isSubmitting}>
            {msgs.send}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default CabinForm
